import { fuzzyIncludes } from '@/app/utils/string';
import { sumBy } from 'lodash-es';
import { CategoryTree, CategoryTreeLeaf, CategoryTreeNode, Diagram } from '../types';

export const filterCategoryNodes = (
	categories: CategoryTreeNode[],
	query?: string
): CategoryTreeNode[] => {
	if (!query) {
		return categories;
	}
	return categories
		.map(category => {
			if (category.searchables.some(searchable => filterTokenMatch(searchable, query))) {
				return category;
			}
			return {
				...category,
				assemblies: filterCategories(category.assemblies, query)
			};
		})
		.filter(({ searchables, ...category }) => {
			if (searchables.some(searchable => filterTokenMatch(searchable, query))) {
				return true;
			}
			return category.assemblies.length > 0;
		});
};
export const filterCategories = (categories: CategoryTree[], query?: string): CategoryTree[] => {
	if (!query) {
		return categories;
	}
	return categories
		.map(category => {
			if (category.searchables.some(searchable => filterTokenMatch(searchable, query))) {
				return category;
			}

			if (category.kind === 'leaf') {
				return {
					...category,
					diagrams: filterDiagrams(category.diagrams, query)
				};
			}
			return {
				...category,
				assemblies: filterCategories(category.assemblies, query)
			};
		})
		.filter(({ searchables, ...category }) => {
			if (searchables.some(searchable => filterTokenMatch(searchable, query))) {
				return true;
			}

			if (category.kind === 'leaf') {
				return category.diagrams.length > 0;
			}
			return category.assemblies.length > 0;
		});
};

export const filterOtherCategory = (
	category: CategoryTreeLeaf,
	query?: string
): CategoryTreeLeaf | null => {
	if (!query) {
		return category;
	}

	const diagrams = filterDiagrams(category.diagrams, query);

	if (diagrams.length === 0) {
		return null;
	}

	return {
		...category,
		diagrams
	};
};

export const filterDiagrams = (diagrams: Diagram[], query: string): Diagram[] => {
	return diagrams.filter(({ searchables, partSlots }) => {
		const isDiagramMatching = searchables.some(searchable => filterTokenMatch(searchable, query));
		const isAnyPartMatching = partSlots
			.flatMap(partSlot => (partSlot.kind === 'assembly' ? partSlot.assemblies : []))
			.flatMap(({ searchables }) => searchables)
			.some(searchable => filterTokenMatch(searchable, query));

		return isAnyPartMatching || isDiagramMatching;
	});
};

// make it less expensive
const CACHE_TOKENS = new Map<string, Set<string>>();
export const tokenized = (src: string) => {
	const existing = CACHE_TOKENS.get(src);
	if (existing) {
		return existing;
	}
	const tokens = new Set(
		src.toLowerCase().replaceAll('\n', ' ').replaceAll('\t', ' ').replaceAll(',', ' ').split(' ')
	);
	CACHE_TOKENS.set(src, tokens);
	return tokens;
};

export const filterTokenMatch = (src: string | null | undefined, query: string) => {
	if (!src) {
		return false;
	}
	const l = tokenized(src);
	const r = tokenized(query);

	if (r.isSubsetOf(l)) {
		return true;
	}

	return fuzzyIncludes(src, query);
};

export const scoreTokenMatch = (src: string | null | undefined, query: string) => {
	if (!src) {
		return 0;
	}
	const l = tokenized(src);
	const r = tokenized(query);

	return l.size - sumBy([...r.values()], each => (l.has(each) ? 1 : 0));
};
