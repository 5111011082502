import { DiagramPartSlot } from '../types';

export const assignDiagramPartSlotCode = (partSlots: DiagramPartSlot[]) => {
	const assemblyCodes = new Map<string, string>();

	let index = 1;
	return partSlots.map(partSlot => {
		if (partSlot.kind === 'reference') {
			return partSlot;
		}

		const existing = partSlot.assemblies.map(({ id }) => assemblyCodes.get(id)).at(0);
		if (existing) {
			const assemblies = partSlot.assemblies.map(assembly => ({ ...assembly, code: existing }));
			assemblies.forEach(({ id, code }) => assemblyCodes.set(id, code));
			return {
				...partSlot,
				code: existing,
				assemblies
			};
		}

		const code = `${index}`;
		index += 1;
		const assemblies = partSlot.assemblies.map(assembly => ({ ...assembly, code: code }));
		assemblies.forEach(({ id, code }) => assemblyCodes.set(id, code));

		return {
			...partSlot,
			code: code,
			assemblies
		};
	});
};
