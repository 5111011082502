import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import {
	ArrowsPointingInIcon,
	ArrowUturnLeftIcon,
	EllipsisVerticalIcon,
	EyeIcon,
	EyeSlashIcon,
	MagnifyingGlassIcon,
	MinusIcon,
	PlusIcon
} from '@heroicons/react/24/solid';
import { Menu, Switch } from '@mantine/core';
import { CategoryTreeLeaf, Diagram } from '../../types';

type PartsToolbarProps = InheritableElementProps<
	'div',
	{
		category: CategoryTreeLeaf;
		diagram: Diagram;
		zoom: number;
		actions: {
			zoom: {
				in: () => void;
				out: () => void;
			};
			hide: (hide: boolean) => void;
			menu: {
				custom: {
					add: () => void;
				};
				diagram: {
					recenter: () => void;
					resetZoom: () => void;
					resetAll: () => void;
				};
			};
		};
	}
>;

export const PartsToolbar = ({
	className,
	category,
	diagram,
	zoom,
	actions,
	...rest
}: PartsToolbarProps) => {
	return (
		<div
			className={tlsx(
				'absolute bottom-6 flex items-center min-w-40 rounded-lg shadow-md border px-1 bg-white z-10',
				className
			)}
			{...rest}
		>
			<div className="flex items-center py-1 px-1 gap-1.5">
				<button
					type="button"
					className="flex items-center p-1 rounded text-sm hover:bg-gray-50 active:bg-gray-50 select-none"
					onClick={actions.zoom.in}
				>
					<PlusIcon className="size-4" />
				</button>
				<span className="text-sm min-w-[4ch] text-end select-none">{Math.round(zoom * 100)}%</span>
				<button
					type="button"
					className="flex items-center p-1 rounded text-sm hover:bg-gray-50 active:bg-gray-50 select-none"
					onClick={actions.zoom.out}
				>
					<MinusIcon className="size-4" />
				</button>
			</div>
			<Switch
				className="mx-3"
				onChange={e => actions.hide(e.target.checked)}
				onLabel={<EyeSlashIcon className="size-4" />}
				offLabel={<EyeIcon className="size-4" />}
			/>
			<Menu shadow="md" width={200}>
				<Menu.Target>
					<button
						type="button"
						className="flex items-center p-1 rounded text-sm hover:bg-gray-50 active:bg-gray-50 select-none mr-2"
					>
						<EllipsisVerticalIcon className="size-4" />
					</button>
				</Menu.Target>

				<Menu.Dropdown>
					<Menu.Label>Manual part</Menu.Label>
					<Menu.Item
						type="button"
						icon={<PlusIcon className="size-4" />}
						onClick={actions.menu.custom.add}
					>
						Add part
					</Menu.Item>
					<Menu.Divider />
					<Menu.Label>Diagram</Menu.Label>
					<Menu.Item
						type="button"
						icon={<ArrowsPointingInIcon className="size-4" />}
						onClick={actions.menu.diagram.recenter}
					>
						Re-center
					</Menu.Item>
					<Menu.Item
						type="button"
						icon={<MagnifyingGlassIcon className="size-4" />}
						onClick={actions.menu.diagram.resetZoom}
					>
						Reset Zoom
					</Menu.Item>
					<Menu.Item
						type="button"
						color="red"
						className="group"
						icon={<ArrowUturnLeftIcon className="size-4" />}
						onClick={actions.menu.diagram.resetAll}
					>
						Reset View
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		</div>
	);
};
