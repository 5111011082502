import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import { useMeasurement } from '@/app/hooks/use-measure';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Highlight, Indicator } from '@mantine/core';
import { sum, uniqBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useSearchValue } from '../../hooks/use-search-value';
import {
	CategoryTree,
	CategoryTreeLeaf,
	CategoryTreeNode,
	Diagram,
	PartsFormData
} from '../../types';
import { categoryLeaves } from '../../utils';
import { tokenized } from '../../utils/search';

type PartsNavigationProps = InheritableElementProps<
	'div',
	{
		cut?: CategoryTreeNode | null;
		other?: CategoryTreeLeaf | null;
		selected?: CategoryTreeLeaf | null;
		diagram?: Diagram | null;
		selection: PartsFormData;
		open: boolean;
		actions: {
			view: {
				open: () => void;
				close: () => void;
			};
			category: {
				set: (category: CategoryTreeLeaf) => void;
			};
			diagram: { set: (diagram: Diagram) => void };
		};
	}
>;

export const PartsNavigation = ({
	className,
	cut,
	other,
	selected: selectedCategory,
	diagram: selectedDiagram,
	selection,
	open,
	actions,
	...rest
}: PartsNavigationProps) => {
	const { value: nav } = useMeasurement('navigation-bar');

	const categories = useMemo(() => cut?.assemblies ?? [], [cut]);

	const showingAll = useMemo(
		() => selectedCategory && other && selectedCategory.id === other.id,
		[selectedCategory, other]
	);

	useEffect(() => {
		if (!selectedCategory || !selectedDiagram) {
			actions.view.open();
		}
	}, [selectedCategory, selectedDiagram]);

	return (
		<>
			<div
				className={tlsx('absolute top-4 left-4 flex gap-4 z-20', {
					'inset-4 bottom-0': !selectedCategory || !selectedDiagram
				})}
			>
				{!showingAll && (
					<div
						className={tlsx(
							'h-fit flex flex-col rounded-lg shadow border bg-white p-2 flex-shrink-0 transition-all duration-300 overflow-auto text-base font-semibold',
							{ '!w-80 shadow-none': open },
							className
						)}
						style={{
							width: selectedCategory
								? `${Math.min(20, selectedCategory.description.length * 0.75 + 3)}rem`
								: '18rem',
							maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 7rem)`
						}}
						{...rest}
					>
						<button
							type="button"
							className="group flex items-center justify-between py-1.5 px-3 text-start"
							onClick={() => {
								if (!selectedCategory || !selectedDiagram) {
									return;
								}

								if (open) {
									actions.view.close();
								} else {
									actions.view.open();
								}
							}}
						>
							<h2 className="text-gray-900 text-base font-semibold">
								{!open && selectedCategory ? selectedCategory.description : cut?.description}
							</h2>
							<div className="flex items-center p-1 rounded text-sm group-hover:bg-gray-50 group-active:bg-gray-50 select-none">
								<ChevronRightIcon
									className={tlsx('size-4 transition-all', { 'rotate-180': open })}
								/>
							</div>
						</button>

						{open && (
							<div className="w-full p-2 empty:hidden">
								{categories.map(c => (
									<PartsCategoryTree
										key={c.id}
										category={c}
										selected={selectedCategory}
										selection={selection}
										onClick={actions.category.set}
									/>
								))}
							</div>
						)}
					</div>
				)}

				{selectedCategory && !selectedDiagram && (
					<div
						className="flex-1 h-max flex flex-wrap gap-3 overflow-auto"
						style={{
							maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 5.5rem)`
						}}
					>
						{selectedCategory.diagrams.map(diagram => (
							<PartsDiagramPreview
								key={diagram.id}
								diagram={diagram}
								selection={selection}
								onClick={() => {
									actions.view.close();
									actions.diagram.set(diagram);
								}}
							/>
						))}
					</div>
				)}
			</div>

			{!!selectedCategory?.diagrams?.length && !selectedDiagram && (
				<div className="absolute inset-0 z-[15] backdrop-blur-sm bg-[#FCFDFD]" />
			)}
		</>
	);
};

type PartsCategoryTreeProps = InheritableElementProps<
	'button',
	{
		category: CategoryTree;
		selected?: CategoryTreeLeaf | null;
		selection: PartsFormData;
		onClick: (category: CategoryTreeLeaf) => void;
	}
>;

export const PartsCategoryTree = ({
	className,
	category,
	selected,
	selection,
	onClick,
	...rest
}: PartsCategoryTreeProps) => {
	const q = useSearchValue();
	const [open, setOpen] = useState(false);
	const leaves = useMemo(() => categoryLeaves(category), [category]);
	const count = useMemo(() => {
		const assemblies = uniqBy(
			leaves
				.flatMap(({ diagrams }) => diagrams)
				.flatMap(({ partSlots }) => partSlots)
				.flatMap(partSlot => (partSlot.kind === 'assembly' ? partSlot.assemblies : [])),
			({ id }) => id
		);
		return sum(assemblies.map(({ id }) => ((selection[id]?.quantity ?? 0) > 0 ? 1 : 0)));
	}, [leaves, selection]);

	const defaultOpen = useMemo(
		() => leaves.some(({ id }) => selected?.id === id),
		[leaves, selected]
	);

	useEffect(() => {
		if (defaultOpen) {
			setOpen(true);
		}
	}, [selected]);

	if (category.kind === 'leaf') {
		return (
			<button
				type="button"
				className={tlsx(
					'flex items-center text-base w-full font-medium text-start text-gray-800 ml-3 px-3 py-1 rounded-md hover:bg-gray-50 active:bg-gray-50 2 mt-3 first:mt-0',
					{
						'bg-blue-600 text-white hover:bg-blue-600 active:bg-blue-600':
							selected?.id === category.id
					},
					className
				)}
				onClick={() => onClick(category)}
			>
				<Highlight
					highlight={q ? [...tokenized(q)] : ''}
					highlightStyles={{
						'--tw-text-opacity': 1,
						color: 'rgb(0 0 0 / var(--tw-text-opacity))'
					}}
				>
					{category.description}
				</Highlight>
				{count > 0 && (
					<span
						className={tlsx('ml-3 text-sm leading-none font-medium text-blue-600 min-w-[2ch]', {
							'text-white': selected?.id === category.id
						})}
					>
						{count}
					</span>
				)}
			</button>
		);
	}
	return (
		<div className="mt-3 first:mt-0">
			<button
				type="button"
				className={tlsx(
					'group flex w-full items-center gap-2 text-start hover:bg-gray-50 active:bg-gray-50 py-1 px-2',
					className
				)}
				onClick={() => setOpen(prev => !prev)}
				{...rest}
			>
				<ChevronRightIcon className="size-3 group-data-[open]:rotate-90" />
				<Highlight
					component="span"
					className="text-base text-start font-medium text-gray-800"
					highlight={q ? [...tokenized(q)] : ''}
				>
					{category.description}
				</Highlight>
				{count > 0 && (
					<span className="ml-1 text-sm leading-none font-medium text-blue-600 min-w-[2ch]">
						{count}
					</span>
				)}
			</button>
			<div className={tlsx('mt-3 pl-4', { hidden: !open })}>
				{category.assemblies.map(sub => (
					<PartsCategoryTree
						key={sub.id}
						category={sub}
						selected={selected}
						selection={selection}
						onClick={onClick}
					/>
				))}
			</div>
		</div>
	);
};

type PartsDiagramPreviewProps = InheritableElementProps<
	'button',
	{
		diagram: Diagram;
		selection: PartsFormData;
		onClick: () => void;
	}
>;

export const PartsDiagramPreview = ({
	className,
	diagram,
	selection,
	onClick,
	...rest
}: PartsDiagramPreviewProps) => {
	const q = useSearchValue();
	const count = useMemo(() => {
		const assemblies = uniqBy(
			diagram.partSlots.flatMap(partSlot =>
				partSlot.kind === 'assembly' ? partSlot.assemblies : []
			),
			({ id }) => id
		);
		return sum(assemblies.map(({ id }) => ((selection[id]?.quantity ?? 0) > 0 ? 1 : 0)));
	}, [diagram, selection]);
	return (
		<Indicator
			className="text-sm"
			zIndex={90}
			disabled={count === 0}
			offset={16}
			size="1.5rem"
			label={`${count}`}
		>
			<button
				type="button"
				className={tlsx(
					'flex flex-col items-center justify-between gap-2 py-3 px-5 bg-white border rounded-lg w-56 h-64 xl:w-60 xl:h-72',
					className
				)}
				onClick={onClick}
				{...rest}
			>
				<div className="flex-1 grid place-items-center/">
					<ImageWithSkeleton
						className="size-48 xl:w-52 xl:h-52 object-scale-down"
						src={diagram.image.thumb}
						loading="lazy"
					/>
				</div>
				<Highlight
					component="span"
					className="text-xs font-medium text-gray-900"
					highlight={q ? [...tokenized(q)] : ''}
				>
					{`${diagram.code}: ${diagram.description}`}
				</Highlight>
			</button>
		</Indicator>
	);
};
