import { splitCamelCaseAndAcronyms } from '@/app/utils/string';
import { DiagramAssemblyTag } from '../types';

export const transformTag = (tag: string): DiagramAssemblyTag | null => {
	if (tag.replaceAll('_', '').toLowerCase() === 'falsevariants') {
		return null;
	}
	const [description, value, ...rest] = tag.split('_');
	if (['yes', 'with'].includes(value.toLowerCase())) {
		return {
			kind: 'yes',
			description: splitCamelCaseAndAcronyms(description)
		};
	}
	if (['no', 'without'].includes(value.toLowerCase())) {
		return {
			kind: 'no',
			description: splitCamelCaseAndAcronyms(description)
		};
	}
	return {
		kind: 'other',
		description: `${splitCamelCaseAndAcronyms(description)} ${value} ${rest.join(' ')}`
	};
};
