import { Badge } from '@/app/atoms/badge';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { CheckIcon, ChevronRightIcon, TagIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Checkbox, CheckboxProps, Highlight } from '@mantine/core';
import { Fragment } from 'react';
import { useSearchValue } from '../../hooks/use-search-value';
import { DiagramAssembly } from '../../types';
import { tokenized } from '../../utils/search';
import { PartsHotpot } from '../parts-hotspot';

type PartsAssemblyDisplayProps = InheritableElementProps<
	'div',
	{
		assembly: DiagramAssembly;
		checkbox?: Omit<CheckboxProps, 'id' | 'radius' | 'size'>;
		fitment?: boolean;
		onClick: () => void;
	}
>;

export const PartsAssemblyDisplay = ({
	className,
	assembly,
	checkbox,
	fitment: tags,
	onClick,
	...rest
}: PartsAssemblyDisplayProps) => {
	const q = useSearchValue();

	return (
		<div
			className={tlsx(
				'flex flex-col items-center gap-2 px-6 py-5 rounded-lg shadow-sm border bg-white',
				className
			)}
			{...rest}
		>
			<div className="flex items-center gap-4 w-full">
				<Checkbox radius="100%" size="lg" {...checkbox} />
				<PartsHotpot
					className="p-1 h-[3ch] min-w-[3ch] text-sm"
					code={assembly.code}
					kind="assembly"
					highlighted
					onClick={onClick}
				/>
				<Highlight component="span" className="text-base" highlight={q ? [...tokenized(q)] : ''}>
					{assembly.description}
				</Highlight>
			</div>

			<div className="flex items-center flex-wrap gap-1 w-full mt-1 empty:hidden">
				{assembly.hcas.map((hca, index) => (
					<Fragment key={`${hca}-${index}`}>
						<span className="text-sm text-gray-600">{hca}</span>
						<ChevronRightIcon className="size-3 last:hidden" />
					</Fragment>
				))}
			</div>

			{tags && !!assembly.tags?.length && (
				<div className="flex items-center flex-wrap w-full mt-1 gap-2">
					{assembly.tags.map(({ kind, description }, index) => {
						const Icon = kind === 'yes' ? CheckIcon : kind === 'no' ? XMarkIcon : TagIcon;
						return (
							<Badge
								key={`${description}-${index}`}
								size="small"
								rounded
								variant={kind === 'yes' ? 'green' : kind === 'no' ? 'red' : 'white'}
							>
								<Badge.LeadingIcon as={Icon} />
								{description}
							</Badge>
						);
					})}
				</div>
			)}
		</div>
	);
};
