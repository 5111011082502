import { jobsMutations, jobsQueries } from '@sdk/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useReloadDiagrams = () => {
	const queryClient = useQueryClient();

	return useMutation({
		...jobsMutations.removePartAssembliesTree,
		onSuccess: (_, { jobId }) => {
			queryClient.invalidateQueries({
				queryKey: jobsQueries.getPartAssembliesTree({ jobId }).queryKey
			});
		}
	});
};
