import { sanitisePropString } from '@/app/features/vehicle/components/vehicle-selector-form/utils';
import {
	getSupportedBodyNumDoors,
	getSupportedBodyTypes,
	isSupportedBodyType,
	isSupportedNumDoorsValue
} from '@/app/utils/collision-map';
import {
	OemVehicleProperty,
	SearchVehiclePayload,
	UvdbProperties,
	UvdbProperty,
	UvdbPropertyKey,
	VehicleConfiguration
} from '@sdk/lib';
import { vehicleQueries } from '@sdk/react';
import { useQuery } from '@tanstack/react-query';
import { isDefined } from '@utils/common';
import {
	filter,
	flatten,
	groupBy,
	intersection,
	keys,
	map,
	omit,
	pick,
	reduce,
	uniqBy
} from 'lodash-es';
import { useMemo } from 'react';

export type AggregatedVehicleOptions = {
	oemProperties: OemVehicleProperty[];
	uvdbProperties: UvdbProperty[];
};
export const UVDB_BODY_TYPE = 'UvdbBodyType';
export const UVDB_BODY_NUM_DOORS = 'UvdbBodyNumDoors';
const REQUIRED_UVDB_PROPERTIES = [UVDB_BODY_TYPE, UVDB_BODY_NUM_DOORS];

const SELECT_OPTIONS_BODY_TYPES: UvdbProperty[] = [
	{ id: 'UBOT118', type: 'UvdbBodyType', name: 'Sedan' },
	{ id: 'UBOT120', type: 'UvdbBodyType', name: 'Coupe' },
	{ id: 'UBOT121', type: 'UvdbBodyType', name: 'SUV' },
	{ id: 'UBOT123', type: 'UvdbBodyType', name: 'Wagon' },
	{ id: 'UBOT125', type: 'UvdbBodyType', name: 'Hatchback' },
	{ id: 'UBOT122', type: 'UvdbBodyType', name: 'Pickup' },
	{ id: 'UBOT109', type: 'UvdbBodyType', name: 'Extended Cab Pickup' },
	{ id: 'UBOT129', type: 'UvdbBodyType', name: 'Passenger Van' },
	{ id: 'UBOT133', type: 'UvdbBodyType', name: 'Cab & Chassis' }
];

// Only count passenger doors
const SELECT_OPTIONS_NUM_DOORS: UvdbProperty[] = [
	// { id: "UBND18", type: "UvdbBodyNumDoors", name: "0" },
	// { id: "UBND22", type: "UvdbBodyNumDoors", name: "1" },
	{ id: 'UBND14', type: 'UvdbBodyNumDoors', name: '2 Passenger Doors' },
	// { id: "UBND17", type: "UvdbBodyNumDoors", name: "3" },
	{ id: 'UBND15', type: 'UvdbBodyNumDoors', name: '4 Passenger Doors' }
	// { id: "UBND16", type: "UvdbBodyNumDoors", name: "5" },
];

// oem keys that may not make sense to an assessor
export const EXCLUDED_OEM_PROPS: string[] = [
	'ProductionMonthFrom',
	'ProductionMonthTo',
	'ProductionYearFrom',
	'ProductionYearTo'
];

export const EXCLUDED_UVDB_PROPS: UvdbPropertyKey[] = [
	'UvdbMonthFrom',
	'UvdbMonthTo',
	'UvdbYearFrom',
	'UvdbYearTo'
];

export const useVehicleSearch = (params: SearchVehiclePayload) => {
	const { data, isLoading, ...rest } = useQuery(vehicleQueries.searchVehicles(params));

	const selectOptions = useMemo(() => {
		return getSelectOptions(data?.vehicleConfigurations ?? []);
	}, [data]);

	// Body type and Num Doors are special cases handled by vehicle selector,
	// so remove them from variants
	const vehicles = useMemo(
		() =>
			(data?.vehicleConfigurations ?? []).map(vehicle => ({
				...vehicle,
				uvdbProperties: omit(vehicle.uvdbProperties, REQUIRED_UVDB_PROPERTIES)
			})),
		[data]
	);

	const uvdbProperties = useMemo<UvdbProperty[]>(() => {
		if (vehicles.length === 1) {
			return [];
		}

		// take only properties that exist in every vehicle configuration,
		// then pick values that have variations
		const allUvdbProps: UvdbProperties[] = map(vehicles, 'uvdbProperties');
		const commonKeys = intersection(...allUvdbProps.map(keys));
		const commonProps = allUvdbProps.map(obj => pick(obj, commonKeys));
		const uniqProps: UvdbProperty[] = uniqBy<UvdbProperty>(
			flatten(map(commonProps, uvdbPropertiesToArray)),
			'id'
		);

		const diffUvdb = filter(
			groupBy(uniqProps, 'type'),
			(group, key) => group.length > 1 && !EXCLUDED_UVDB_PROPS.includes(key as UvdbPropertyKey)
		);

		return flatten(diffUvdb);
	}, [vehicles]);

	const oemProperties = useMemo<OemVehicleProperty[]>(() => {
		if (vehicles.length === 1) {
			return [];
		}

		// same logic as for uvdbProperties;
		// also excludes props already in uvdb list
		const oemPropsLists: OemVehicleProperty[][] = map(vehicles, mapOemProps);

		// prop types that exist in every vehicle
		const commonTypes = intersection(...oemPropsLists.map(veh => map(veh, 'type')));

		// only looking for differences in props, which exist in every vehicle configuration,
		// so filter OUT all other vehicle-specific props
		const filteredProps = oemPropsLists.map(props =>
			props.filter(i => commonTypes.includes(i.type))
		);
		const uniqProps = uniqBy(flatten(filteredProps), compareOemCb);
		const uvdbKeysAsLabels = uvdbProperties.map(v => sanitisePropString(v.type));

		const diffOem = filter(
			groupBy(uniqProps, 'type'),
			(group, key) =>
				group.length > 1 && !EXCLUDED_OEM_PROPS.includes(key) && !uvdbKeysAsLabels.includes(key)
		);

		return flatten(diffOem);
	}, [vehicles]);

	return {
		vehicles,
		chassis: data?.chassisNumber,
		aggregates: { differentOptions: { oemProperties, uvdbProperties }, selectOptions },
		isLoading,
		notFound: !isLoading && !data?.vehicleConfigurations?.length,
		...rest
	};
};

const mapOemProps = (c: VehicleConfiguration) => {
	return c.properties.map((v): OemVehicleProperty => {
		const splitValue = String(v.value).split(' ');

		if (v.type !== 'Option' || splitValue.length <= 1) {
			return v;
		}

		// "Option" is a collective property type, which currently has 2 possible values:
		// "trimcolor <colorCode>" and "framecolor <colorCode>"
		// This tries to convert them into separate types with the color code as a value.
		const subType = splitValue.shift();
		v.type = `${v.type} (${subType})`;
		v.value = splitValue.join(' ');

		return v;
	});
};

export const compareOemCb = (v: OemVehicleProperty) => `${v.type}:${v.value}`;

export const uvdbPropertiesToArray = (uvdbProps: UvdbProperties) => {
	return reduce(
		uvdbProps,
		(acc: UvdbProperty[], p) => {
			if (Array.isArray(p)) {
				acc = acc.concat(p);
			} else if (p) {
				acc.push(p);
			}

			return acc;
		},
		[]
	);
};

export const extractUvdbProps = (data: VehicleConfiguration[]) => {
	const uvdbProps = map(data, 'uvdbProperties');

	return uniqBy(flatten(map(uvdbProps, uvdbPropertiesToArray)), 'id');
};

export const extractOemProps = (data: VehicleConfiguration[]) => {
	const oemProps = map(data, 'properties');

	return uniqBy(flatten(oemProps), compareOemCb);
};

const getSelectOptions = (
	vehicles: VehicleConfiguration[]
): Partial<Record<UvdbPropertyKey, UvdbProperty[]>> => {
	// trim down selection to what's supported and based properties already defined from the vehicle configurations
	const vehicleBodyTypes: UvdbProperty[] = uniqBy(
		vehicles.flatMap(v => v.uvdbProperties.UvdbBodyType).filter(isDefined),
		'id'
	);

	const convertToPassengerDoors: Record<string, string> = {
		UBND17: 'UBND14', // 3 doors to 2 passenger doors
		UBND16: 'UBND15' // 5 doors to 4 passenger doors
	};

	const vehicleNumDoors: UvdbProperty[] = uniqBy(
		vehicles
			.flatMap(v => v.uvdbProperties.UvdbBodyNumDoors)
			.filter(isDefined)
			.map(v => {
				let newNumDoors;
				const newId = convertToPassengerDoors[v.id];

				if (newId) {
					newNumDoors = SELECT_OPTIONS_NUM_DOORS.find(v => v.id === newId);
				}

				return newNumDoors ?? v;
			}),
		'id'
	);

	// nothing to filter - return all possible options
	if (!vehicleBodyTypes.length && !vehicleNumDoors.length) {
		return {
			UvdbBodyType: SELECT_OPTIONS_BODY_TYPES,
			UvdbBodyNumDoors: SELECT_OPTIONS_NUM_DOORS
		};
	}

	// 1. Get num doors from response, filter out if not in CMs
	let numDoorsOptions = vehicleNumDoors.filter(v => isSupportedNumDoorsValue(v.id));

	// 2. Check if body types from response exist in CMs for these num doors.
	// If no num doors, runs general validity check.
	const numDoorsIds = map(numDoorsOptions, 'id');
	let bodyTypeOptions = vehicleBodyTypes.filter(v => isSupportedBodyType(v.id, numDoorsIds));

	if (!bodyTypeOptions.length) {
		// fall back to the full supported list for given num doors
		// numDoorsIds is validated by this point -> supported body types options will sure exist
		bodyTypeOptions = intoBodyTypeOptions(getSupportedBodyTypes(numDoorsIds));
	}

	if (!numDoorsOptions.length) {
		// fall back to the standard options
		numDoorsOptions = intoNumDoorsOptions(getSupportedBodyNumDoors(map(bodyTypeOptions, 'id')));
	}

	return {
		UvdbBodyType: bodyTypeOptions,
		UvdbBodyNumDoors: numDoorsOptions
	};
};

const intoBodyTypeOptions = (bodyTypes: string[]): UvdbProperty[] =>
	SELECT_OPTIONS_BODY_TYPES.filter(({ id }) => bodyTypes.includes(id));
const intoNumDoorsOptions = (numDoors: string[]): UvdbProperty[] =>
	SELECT_OPTIONS_NUM_DOORS.filter(({ id }) => numDoors.includes(id));
