import { DiagramAssembly, DiagramPartSlot } from '../types';

export const partSlotClassificationSortKey = (partSlot: DiagramPartSlot) => {
	if (partSlot.kind === 'reference') {
		return 1;
	}
	return partSlot.assemblies.some(({ hcas }) => hcas.length > 0) ? -1 : 1;
};

export const partSlotHcaSortKey = (partSlot: DiagramPartSlot) => {
	if (partSlot.kind === 'reference') {
		return 'z';
	}
	return partSlot.assemblies
		.map(assemblyHcaSortKey)
		.reduce((acc, curr) => (curr < acc ? curr : acc), 'z');
};

export const assemblyHcaSortKey = (assembly: DiagramAssembly) => {
	if (assembly.hcas.length === 0) {
		return assembly.description;
	}
	return `${assembly.description} - ${assembly.hcas[0]}`;
};

export const assemblyClassificationSortKey = (assembly: DiagramAssembly) =>
	assembly.hcas.length > 0 ? -1 : 1;
