import {
	CloseJobRequest,
	RemoveJobPartAssembliesTreeRequest,
	RemoveJobPartAssembliesTreeResult,
	RemoveJobRequest,
	RemoveJobResult,
	StorefrontError,
	UpsertJobCollisionsRequest,
	UpsertJobCollisionsResult,
	UpsertJobPartsRequest,
	UpsertJobPartsResult,
	UpsertJobRequest,
	UpsertJobResult,
	UpsertJobVehicleRequest,
	UpsertJobVehicleResult
} from '@sdk/lib';
import { getApi, unwrap } from '../utils/helpers';
import { mutationOptions } from '../utils/mutation-options';

export const jobsMutations = {
	remove: mutationOptions<RemoveJobResult, StorefrontError, RemoveJobRequest>({
		mutationKey: ['jobs', 'remove'],
		mutationFn: payload => unwrap(() => getApi().jobs.remove(payload))
	}),
	close: mutationOptions<RemoveJobResult, StorefrontError, CloseJobRequest>({
		mutationKey: ['jobs', 'close'],
		mutationFn: payload => unwrap(() => getApi().jobs.close(payload))
	}),
	upsert: mutationOptions<UpsertJobResult, StorefrontError, UpsertJobRequest>({
		mutationKey: ['jobs', 'upsert'],
		mutationFn: payload => unwrap(() => getApi().jobs.upsert(payload))
	}),
	upsertCollisions: mutationOptions<
		UpsertJobCollisionsResult,
		StorefrontError,
		UpsertJobCollisionsRequest
	>({
		mutationKey: ['jobs', 'upsertCollisions'],
		mutationFn: payload => unwrap(() => getApi().jobs.upsertCollisions(payload))
	}),
	upsertParts: mutationOptions<UpsertJobPartsResult, StorefrontError, UpsertJobPartsRequest>({
		mutationKey: ['jobs', 'upsertParts'],
		mutationFn: payload => unwrap(() => getApi().jobs.upsertParts(payload))
	}),
	upsertVehicle: mutationOptions<UpsertJobVehicleResult, StorefrontError, UpsertJobVehicleRequest>({
		mutationKey: ['jobs', 'upsertVehicle'],
		mutationFn: payload => unwrap(() => getApi().jobs.upsertVehicle(payload))
	}),
	removePartAssembliesTree: mutationOptions<
		RemoveJobPartAssembliesTreeResult,
		StorefrontError,
		RemoveJobPartAssembliesTreeRequest
	>({
		mutationKey: ['jobs', 'removePartAssembliesTree'],
		mutationFn: payload => unwrap(() => getApi().jobs.removePartAssembliesTree(payload))
	})
};
