import { BasicTable } from '@/app/molecules/order-table';
import { order } from '@/sdk/reflect/reflect';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { memo } from 'react';
import { useOrderTableItems } from '../../hooks/use-order-table-items';
import { orderColumnBuilder } from '../../table-builder';
import { OrderSection } from '../order-section';

type DraftOrderReuqestProps = {
	model: order.exp.Order;
};

const builder = orderColumnBuilder();
const tableColumns = [
	builder.quantity(),
	builder.name(),
	builder.status(),
	builder.grade(),
	builder.returnPolicy(),
	builder.price()
];

export const PendingOrder = memo(({ model }: DraftOrderReuqestProps) => {
	const { items, subtotal } = useOrderTableItems(model.items);

	const table = useReactTable({
		data: items,
		columns: tableColumns,
		getCoreRowModel: getCoreRowModel()
	});

	return (
		<OrderSection className="bg-white">
			<OrderSection.Content className="flex items-center justify-between border-b">
				<div className="flex items-center gap-2">
					<CheckCircleIcon className="size-5 text-green-500" />
					<span className="font-semibold text-gray-900">Ordered</span>
				</div>
			</OrderSection.Content>
			<OrderSection.Content className="space-y-3">
				<OrderSection.Title>
					{model.vendor.partner.name} (#{model.id})
				</OrderSection.Title>
				<div className="text-sm text-gray-700">
					<span className="font-semibold">Delivery date:</span>{' '}
					{formatDate(model.deliver_before_timestamp, 'dd/MM, hh:mm a')}
				</div>
				<BasicTable className="mt-3" table={table} />
				<BasicTable.Subtotal subtotal={subtotal} />
			</OrderSection.Content>
		</OrderSection>
	);
});
