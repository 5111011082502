import { useWatchForm } from '@/app/hooks/use-watch-form';
import { BasicTable } from '@/app/molecules/order-table';
import { mutations } from '@/sdk/react/mutations';
import { order } from '@/sdk/reflect/reflect';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { memo, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { match } from '@/types/match';
import { queries } from '@/sdk/react/queries';
import { OrderSelection, useOrderTableItems } from '../../hooks/use-order-table-items';
import { orderColumnBuilder, OrderTableItem } from '../../table-builder';
import { AcceptItemDialog, AcceptItemFormData } from '../accept-item-modal';
import { OrderSection } from '../order-section';
import { RejectItemDialog, RejectItemDialogData } from '../return-item-modal';

type AcknowledgedOrderProps = {
	jobId: string;
	model: order.exp.Order;
};

const builder = orderColumnBuilder();
const getTableColumns = (selection: OrderSelection) => {
	const columns = [
		builder.select(),
		builder.quantity(),
		builder.name(),
		builder.status(),
		builder.grade(),
		builder.returnPolicy(),
		builder.price()
	];

	if (
		Object.values(selection).some(value =>
			match(
				value,
				{
					arrived: () => false,
					returned: () => true,
					pending: () => false
				},
				() => false
			)
		)
	) {
		columns.splice(4, 0, builder.returnDecision());
	}

	return columns;
};

export const AcknowledgedOrder = memo(({ jobId, model }: AcknowledgedOrderProps) => {
	const client = useQueryClient();
	const { mutateAsync: onSave } = useMutation({
		...mutations.orders.update,
		onSuccess: () => {
			client.invalidateQueries(queries.orders.list({ job_id: jobId }));
		}
	});

	const form = useForm<OrderSelection>({
		defaultValues: model.items.reduce((acc, item) => {
			acc[item.id] = item.status;
			return acc;
		}, {} as OrderSelection)
	});

	const selection = useWatchForm(form);
	const [acceptItems, setAcceptItems] = useState(false);
	const [returnItems, setReturnItems] = useState(false);
	const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});
	const { items, subtotal } = useOrderTableItems(model.items, selection);
	const tableColumns = useMemo(() => getTableColumns(selection), [selection]);

	const onSubmit = async (data: OrderSelection) => {
		const items = Object.entries(data).map(([id, status]) => ({ id, status }));
		await onSave({
			job_id: jobId,
			update: {
				id: model.id,
				items
			}
		});

		toast.success('Order updated');
	};

	const table = useReactTable({
		data: items,
		columns: tableColumns,
		state: {
			rowSelection
		},
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel()
	});

	const onAcceptItems = (data: AcceptItemFormData) => {
		const selectedItems = Object.keys(rowSelection).map(Number);
		const itemIds = selectedItems.map(index => items[index].id);
		if (!itemIds.length) {
			return;
		}

		const newSelection = itemIds.reduce((acc, id) => {
			acc[id] = {
				arrived: {
					arrival_at: data.arrivalAt.valueOf(),
					details: data.details ?? null
				}
			};

			return acc;
		}, {} as OrderSelection);

		form.reset({ ...selection, ...newSelection }, { keepDirty: true });
		setAcceptItems(false);
	};

	const onReturnItem = (data: RejectItemDialogData) => {
		const selectedItem = Number(Object.keys(rowSelection)[0]);
		if (isNaN(selectedItem)) {
			return;
		}

		const itemId = items[selectedItem].id;
		form.setValue(itemId, {
			returned: {
				reason: data.reason as order.OrderItemReturnReason,
				quantity: data.quantity,
				details: data.details
			}
		});

		setReturnItems(false);
	};

	const hasSelection = Object.keys(rowSelection).length > 0;
	const targetIndex = Number(Object.keys(rowSelection)[0]);
	const targetItem = isNaN(targetIndex) ? null : items[targetIndex];

	const { arrival, returns } = useAllowedActions(rowSelection, items);

	return (
		<OrderSection className="bg-white">
			<form noValidate onSubmit={form.handleSubmit(onSubmit)}>
				<OrderSection.Content className="flex items-center justify-between border-b">
					<div className="flex items-center gap-2">
						<CheckCircleIcon className="size-5 text-green-500" />
						<span className="font-semibold text-gray-900">Confirmed</span>
					</div>
					<Button variant="default" type="submit">
						Save
					</Button>
				</OrderSection.Content>
				<OrderSection.Content className="space-y-3">
					<OrderSection.Title>
						{model.vendor.partner.name} (#{model.id})
					</OrderSection.Title>
					<div className="flex items-center justify-between mt-3">
						<div className="text-sm text-gray-700">
							<span className="font-semibold leading-8">Delivery date:</span>{' '}
							{formatDate(model.deliver_before_timestamp, 'dd/MM, hh:mm a')}
						</div>
						{hasSelection && (
							<div className="flex items-center gap-2">
								{arrival.visible && (
									<Button variant="default" size="xs" onClick={() => setAcceptItems(true)}>
										{arrival.label}
									</Button>
								)}
								{returns.visible && (
									<Button
										variant="outline"
										size="xs"
										color="red"
										onClick={() => setReturnItems(true)}
									>
										{returns.label}
									</Button>
								)}
							</div>
						)}
					</div>
					<div className="mt-3 overflow-x-auto">
						<BasicTable table={table} />
					</div>
					<BasicTable.Subtotal subtotal={subtotal} />
				</OrderSection.Content>
			</form>
			<AcceptItemDialog
				open={acceptItems}
				item={targetItem}
				onClose={() => setAcceptItems(false)}
				afterLeave={() => setRowSelection({})}
				onSubmit={onAcceptItems}
			/>
			<RejectItemDialog
				open={returnItems}
				item={targetItem}
				afterLeave={() => setRowSelection({})}
				onClose={() => setReturnItems(false)}
				onSubmit={onReturnItem}
			/>
		</OrderSection>
	);
});

type ItemActions = {
	returns: { visible: boolean; label: string };
	arrival: { visible: boolean; label: string };
};

const useAllowedActions = (
	selection: Record<number, boolean>,
	items: OrderTableItem[]
): ItemActions => {
	const defaultActions: ItemActions = {
		arrival: { visible: false, label: '' },
		returns: { visible: false, label: '' }
	};

	// todo decouple
	const actions = useMemo<ItemActions>(() => {
		const selectedKeys = Object.keys(selection).map(key => Number(key));
		if (selectedKeys.length === 0) {
			return defaultActions;
		}

		const selectedItems = selectedKeys.map(key => items[key]);
		if (selectedItems.length === 1) {
			const item = selectedItems[0];
			const allowReturn = item.return_policy !== 'no_returns';

			return match(item.status, {
				arrived: () => {
					return {
						arrival: { visible: true, label: 'Update accept' },
						returns: { visible: allowReturn, label: 'Return item' }
					};
				},
				pending: () => {
					return {
						arrival: { visible: true, label: 'Accept item' },
						returns: { visible: allowReturn, label: 'Return item' }
					};
				},
				returned: () => {
					return {
						arrival: { visible: true, label: 'Accept item' },
						returns: { visible: allowReturn, label: 'Update return' }
					};
				}
			});
		}

		const hasReturns = selectedItems.some(
			item => typeof item.status === 'object' && 'returned' in item.status
		);

		if (hasReturns) {
			// You cannot return multiple items at once
			return defaultActions;
		}

		return {
			arrival: { visible: true, label: 'Accept items' },
			returns: { visible: false, label: '' }
		};
	}, [selection, items]);

	return actions;
};
