export type EnvironmentKey = keyof typeof ENVIRONMENTS;

const ENVIRONMENTS = {
	local: {
		get webKey() {
			const key = import.meta.env.PARTLY_LOCAL_WEB_KEY;
			if (!key) {
				throw new Error(`No PARTLY_LOCAL_WEB_KEY found: ${key}`);
			}
			return key;
		},
		basePath: 'http://localhost:7172',
		driftdbUrl: 'https://api.dev1.partly.pro/api/v1-experimental-async/',
		uvdbRegionId: 'UREG32',
		mixpanelKey: '',
		sentryDSN: '',
		otlpUrl: null
	},
	dev: {
		webKey: 'hZ7bA+HJLQWhy8iF_lwoJ+M+Dz193P4FU03lXg1Fnre52Ke4Khh+AAqzHp7LXwg',
		basePath: 'https://api.repairer-dev.partly.pro',
		uvdbRegionId: 'UREG32',
		mixpanelKey: '4644d9b6628402c9fe5b4e7bc1477b3d',
		driftdbUrl: 'https://api.devpartly.pro/api/v1-experimental-async/',
		sentryDSN:
			'https://66a1f7ee68bd53ca9e26ff12dbd9a44f@o4507103828049920.ingest.de.sentry.io/4507625502736464',
		otlpUrl: null
	},
	dev1: {
		webKey: 'hZ7bA+HJLQWhy8iF_lwoJ+M+Dz193P4FU03lXg1Fnre52Ke4Khh+AAqzHp7LXwg',
		basePath: 'https://api.repairer-dev1.partly.pro',
		uvdbRegionId: 'UREG32',
		mixpanelKey: '4644d9b6628402c9fe5b4e7bc1477b3d',
		driftdbUrl: 'https://api.dev1.partly.pro/api/v1-experimental-async/',
		sentryDSN:
			'https://66a1f7ee68bd53ca9e26ff12dbd9a44f@o4507103828049920.ingest.de.sentry.io/4507625502736464',
		otlpUrl: null
	},
	dev2: {
		webKey: 'hZ7bA+HJLQWhy8iF_lwoJ+M+Dz193P4FU03lXg1Fnre52Ke4Khh+AAqzHp7LXwg',
		basePath: 'https://api.repairer-dev2.partly.pro',
		uvdbRegionId: 'UREG32',
		mixpanelKey: '4644d9b6628402c9fe5b4e7bc1477b3d',
		driftdbUrl: 'https://api.dev2.partly.pro/api/v1-experimental-async/',
		sentryDSN:
			'https://66a1f7ee68bd53ca9e26ff12dbd9a44f@o4507103828049920.ingest.de.sentry.io/4507625502736464',
		otlpUrl: null
	},
	dev3: {
		webKey: 'hZ7bA+HJLQWhy8iF_lwoJ+M+Dz193P4FU03lXg1Fnre52Ke4Khh+AAqzHp7LXwg',
		basePath: 'https://api.repairer-dev3.partly.pro',
		uvdbRegionId: 'UREG32',
		mixpanelKey: '4644d9b6628402c9fe5b4e7bc1477b3d',
		driftdbUrl: 'https://api.dev3.partly.pro/api/v1-experimental-async/',
		sentryDSN:
			'https://66a1f7ee68bd53ca9e26ff12dbd9a44f@o4507103828049920.ingest.de.sentry.io/4507625502736464',
		otlpUrl: null
	},
	dev4: {
		webKey: 'hZ7bA+HJLQWhy8iF_lwoJ+M+Dz193P4FU03lXg1Fnre52Ke4Khh+AAqzHp7LXwg',
		basePath: 'https://api.repairer-dev4.partly.pro',
		uvdbRegionId: 'UREG32',
		mixpanelKey: '4644d9b6628402c9fe5b4e7bc1477b3d',
		driftdbUrl: 'https://api.dev4.partly.pro/api/v1-experimental-async/',
		sentryDSN:
			'https://66a1f7ee68bd53ca9e26ff12dbd9a44f@o4507103828049920.ingest.de.sentry.io/4507625502736464',
		otlpUrl: null
	},
	dev5: {
		webKey: 'hZ7bA+HJLQWhy8iF_lwoJ+M+Dz193P4FU03lXg1Fnre52Ke4Khh+AAqzHp7LXwg',
		basePath: 'https://api.repairer-dev5.partly.pro',
		uvdbRegionId: 'UREG32',
		mixpanelKey: '4644d9b6628402c9fe5b4e7bc1477b3d',
		driftdbUrl: 'https://api.dev5.partly.pro/api/v1-experimental-async/',
		sentryDSN:
			'https://66a1f7ee68bd53ca9e26ff12dbd9a44f@o4507103828049920.ingest.de.sentry.io/4507625502736464',
		otlpUrl: null
	},
	dev6: {
		webKey: 'hZ7bA+HJLQWhy8iF_lwoJ+M+Dz193P4FU03lXg1Fnre52Ke4Khh+AAqzHp7LXwg',
		basePath: 'https://api.repairer-dev6.partly.pro',
		uvdbRegionId: 'UREG32',
		mixpanelKey: '4644d9b6628402c9fe5b4e7bc1477b3d',
		driftdbUrl: 'https://api.dev6.partly.pro/api/v1-experimental-async/',
		sentryDSN:
			'https://66a1f7ee68bd53ca9e26ff12dbd9a44f@o4507103828049920.ingest.de.sentry.io/4507625502736464',
		otlpUrl: null
	},
	production: {
		webKey: 'XeE7qw4pb8W5WJ/I_WIvlsedx4V6j49gtcdFwXgXTulSyk+Bs8xxh5OFLjrkGeQ',
		basePath: 'https://api.au-2.repairer.partly.com',
		uvdbRegionId: 'UREG32',
		mixpanelKey: 'ce88a0d9ed9b08af651e4a5fa4c79eba',
		driftdbUrl: 'https://api.partly.com/api/v1-experimental-async/',
		sentryDSN:
			'https://66a1f7ee68bd53ca9e26ff12dbd9a44f@o4507103828049920.ingest.de.sentry.io/4507625502736464',
		otlpUrl: 'https://alloy.partly.pro/v1/traces'
	},
	'production-eu': {
		webKey: 'QU46bI6vfaM3ogbp_LX0cWr4gBIvonD/qrd9evxoqo/dvTCVLedkZY3jSPqDwuA',
		basePath: 'https://api.eu-1.repairer.partly.com',
		uvdbRegionId: 'UREG35',
		mixpanelKey: 'ce88a0d9ed9b08af651e4a5fa4c79eba',
		driftdbUrl: 'https://api.partly.com/api/v1-experimental-async/',
		sentryDSN:
			'https://66a1f7ee68bd53ca9e26ff12dbd9a44f@o4507103828049920.ingest.de.sentry.io/4507625502736464',
		otlpUrl: 'https://alloy.partly.pro/v1/traces'
	}
};

const getEnvironmentName = () => {
	return import.meta.env.PARTLY_DEFAULT_ENVIRONMENT_KEY;
};

export const getEnvironmentKey = () => {
	const _defaultKey = getEnvironmentName();

	// If the default key is invalid, default to production.
	// This shouldn't happen
	if (!isKeyValid(_defaultKey)) {
		return 'production' as EnvironmentKey;
	}

	return _defaultKey as EnvironmentKey;
};

export const getEnvironment = () => {
	const _defaultKey = getEnvironmentName();

	// If the default key is invalid, default to production.
	// This shouldn't happen
	if (!isKeyValid(_defaultKey)) {
		return ENVIRONMENTS.production;
	}

	// Future: add ability to override the default
	// key at runtime
	const defaultKey = _defaultKey as EnvironmentKey;
	return ENVIRONMENTS[defaultKey];
};

const isKeyValid = (key?: string) => {
	if (!key) {
		return false;
	}

	const keys = Object.keys(ENVIRONMENTS);
	return keys.includes(key);
};

export const getEnvironmentRegion = () => {
	const environment = getEnvironmentKey();
	if (environment === 'production-eu') {
		return 'eu';
	}
	return 'au';
};
