import { tlsx } from '@/app/utils/tw-merge';
import { InheritableProps, InheritableThreeProps } from '@/types/utilties';
import { Decal, Edges, Html, Line } from '@react-three/drei';
import { ReactNode, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as THREE from 'three';
import { useImageTexture } from '../../hooks/use-image-texture';
import { Slice } from '../../types';
import { hexToRgb } from '../../utils/color';
import { polygonNexus, vec2to3, vec3 } from '../../utils/geometry';
import { PartsHotpot } from '../parts-hotspot';

const COLOURS = {
	interactive: {
		foreground: '#9333ea',
		background: '#d8b4fe'
	},
	highlighted: {
		foreground: '#2563eb',
		background: '#60a5fa'
	},
	searched: {
		foreground: '#d97706',
		background: '#fcd34d'
	},
	added: {
		foreground: '#059669',
		background: '#6ee7b7'
	},
	navigable: {
		foreground: '#4f46e5',
		background: '#a5b4fc'
	}
} as const;

type ColorState = keyof typeof COLOURS;

type PartsDiagramFallbackProps = InheritableThreeProps<
	'mesh',
	{
		url: string;
		ratio: number;
		scale: number;
		filter?: 'dimmed' | 'background' | 'opaque';
	}
>;

export const PartsDiagramFallback = ({
	url,
	ratio,
	scale,
	filter,
	...rest
}: PartsDiagramFallbackProps) => {
	return (
		<mesh renderOrder={0} {...rest}>
			<planeGeometry args={[scale, scale * ratio]} />
			<meshBasicMaterial color={0xffffff} opacity={1} transparent toneMapped={false} />
			<Html
				wrapperClass="!z-0"
				position={[0, 0, 0]}
				transform
				occlude="blending"
				distanceFactor={10}
				className={tlsx('flex items-center justify-center pointer-events-none opacity-50', {
					'opacity-100': filter === 'opaque',
					'opacity-5': filter === 'dimmed'
				})}
				style={{
					width: `${scale * 40}px`
				}}
			>
				<img src={url} />
			</Html>
		</mesh>
	);
};

type PartsDiagramBackgroundProps = InheritableThreeProps<
	'mesh',
	{
		url: string;
		ratio: number;
		scale: number;
		filter?: 'dimmed' | 'background' | 'opaque';
		children?: ReactNode;
	}
>;

export const PartsDiagramBackground = ({
	url,
	ratio,
	scale,
	filter,
	children,
	...rest
}: PartsDiagramBackgroundProps) => {
	const texture = useImageTexture(url);
	return (
		<mesh renderOrder={0} {...rest}>
			<planeGeometry args={[scale, scale * ratio]} />
			<meshBasicMaterial
				map={texture}
				color={0xffffff}
				opacity={filter === 'opaque' ? 1 : filter == 'dimmed' ? 0.1 : 0.2}
				transparent
				toneMapped={false}
			/>
			{children}
		</mesh>
	);
};

type PartsDiagramCodeLayerProps = InheritableThreeProps<
	'mesh',
	{
		rect: Slice<THREE.Vector2, 4>;
		color?: `#${string}`;
		opacity?: number;
	}
>;

export const PartsDiagramCodeLayer = ({
	rect,
	color,
	opacity,
	...rest
}: PartsDiagramCodeLayerProps) => {
	const shape = new THREE.Shape(rect);
	return (
		<mesh renderOrder={2} {...rest}>
			<shapeGeometry args={[shape]} />
			<meshBasicMaterial
				color={color ?? '#ffffff'}
				opacity={opacity ?? 1}
				transparent
				toneMapped={false}
			/>
			<Edges
				lineWidth={10}
				color={color ?? '#ffffff'}
				opacity={1}
				transparent
				toneMapped={false}
				renderOrder={0}
			/>
		</mesh>
	);
};

type PartsDiagramHotspotProps = InheritableThreeProps<
	'mesh',
	{
		point: THREE.Vector2;
		code: string | null;
		kind: 'assembly' | 'reference';
		checked?: boolean;
		highlighted?: boolean;
		searched?: boolean;
		far?: boolean;
		className?: string;
		onClick: () => void;
	}
>;

export const PartsDiagramAssemblyHotspot = ({
	className,
	point,
	code,
	kind,
	checked,
	highlighted,
	searched,
	far,
	onClick,
	...rest
}: PartsDiagramHotspotProps) => {
	const shape = new THREE.Shape([point]);
	return (
		<mesh renderOrder={4} {...rest}>
			<shapeGeometry args={[shape]} />
			<Html
				as="div"
				wrapperClass={tlsx(
					'flex items-center justify-center !z-[5]',
					{
						'!z-[6]': searched
					},
					{
						'!z-[7]': highlighted
					},
					className
				)}
				className="bg-white/0 border-0"
				position={vec2to3(point)}
			>
				<PartsHotpot
					code={code}
					kind={kind}
					checked={checked}
					highlighted={highlighted}
					searched={searched}
					far={far}
					onClick={onClick}
				/>
			</Html>
			<meshBasicMaterial color="#ffffff" opacity={0} transparent toneMapped={false} />
		</mesh>
	);
};

type PartsDiagramSegmentProps = InheritableThreeProps<
	'mesh',
	{
		polygon: THREE.Vector2[];
		url: string;
		ratio: number;
		scale: number;
		state: ColorState;
		position?: THREE.Vector3;
		decalOrder?: number;
	}
>;

export const PartsDiagramSegment = ({
	polygon,
	position,
	ratio,
	scale,
	url,
	state,
	decalOrder,
	onClick,
	...rest
}: PartsDiagramSegmentProps) => {
	const [hovering, setHovering] = useState(false);
	const texture = useImageTexture(url);

	const shape = new THREE.Shape(polygon);

	// // scaling polygon hitbox + making it a square for larger area (need to experiment more)
	// const hitbox = new THREE.Shape(scalePolygon(polygonToRect(polygon), 1.25));

	// mesh is not an html with a css class
	useEffect(() => {
		document.body.style.cursor = hovering ? 'pointer' : 'auto';
	}, [hovering]);

	return (
		<mesh
			onClick={onClick}
			onPointerOver={() => setHovering(true)}
			onPointerOut={() => setHovering(false)}
			{...rest}
		>
			<shapeGeometry args={[shape]} />
			<meshBasicMaterial
				color={'#ffffff'}
				opacity={1}
				transparent
				toneMapped={false}
				depthWrite={false}
				depthTest={false}
			/>
			<Decal
				position={position ?? vec3(0, 0, 0)}
				rotation={[0, 0, 0]}
				scale={[scale, scale * ratio, 1]}
				renderOrder={decalOrder ?? 1}
			>
				<meshBasicMaterial
					color={'#ffffff'}
					opacity={1}
					transparent
					toneMapped={false}
					depthWrite={false}
					depthTest={false}
				/>
				<colorReplaceMaterial
					map={texture}
					attach="material"
					target={[0, 0, 0]}
					replacement={hexToRgb(COLOURS[state].foreground)}
					opacity={1}
					tolerance={1}
					transparent
					polygonOffset
					polygonOffsetFactor={-1}
					toneMapped={false}
					depthWrite={false}
					depthTest={false}
				/>
			</Decal>
			<meshBasicMaterial
				color={COLOURS[state].background}
				opacity={state === 'highlighted' ? 0.3 : 0.2}
				transparent
				toneMapped={false}
				depthWrite={false}
				depthTest={false}
			/>
			<Edges
				linewidth={state === 'highlighted' ? 3 : 2}
				scale={1}
				threshold={1}
				color={COLOURS[state].foreground}
			/>
		</mesh>
	);
};

export const PartsDiagramSuspenseSegment = (props: PartsDiagramSegmentProps) => {
	return (
		<ErrorBoundary fallback={null}>
			<PartsDiagramSegment {...props} />
		</ErrorBoundary>
	);
};

type PartsDiagramAssemblyLineProps = Omit<
	InheritableProps<
		typeof Line,
		{
			from: THREE.Vector2[];
			to: THREE.Vector2[];
			state: ColorState;
			dashed?: boolean;
		}
	>,
	'points'
>;

export const PartsDiagramLine = ({
	from,
	to,
	state,
	dashed,
	...rest
}: PartsDiagramAssemblyLineProps) => {
	const line = polygonNexus(from, to);
	return (
		<>
			<Line
				points={line}
				color={COLOURS[state].foreground}
				lineWidth={!dashed ? 3 : 2}
				dashed={dashed}
				dashScale={40}
				opacity={!dashed ? 1 : 0.25}
				transparent
				renderOrder={3}
				{...rest}
			/>
		</>
	);
};
